<template>
    <div id="pay">
        <div>
            <p>您选择了{{params.title}}，需要支付</p>
            <h3>￥ {{params.price}}</h3>
        </div>
        <el-button type="primary" class="see-btn" @click="confirm()">确认预约</el-button>
    </div>
</template>

<script>
import wx from 'weixin-js-sdk';
export default {
    name: "pay",
    data() {
        return {
            params: {}
        };
    },
    created() {
        this.params = this.$route.query;
    },
    methods: {
        confirm() {
            let _that = this;
            this.$post('api/order/ordSubmit', this.params)
                .then(res => {
                    let _prepay = res.datas.prepay;
                    wx.config({
                        debug: false, // 开启调试模式
                        appId: _prepay.appId, // 必填，公众号的唯一标识
                        timestamp: _prepay.timestamp, // 必填，生成签名的时间戳
                        nonceStr: _prepay.nonceStr, // 必填，生成签名的随机串
                        signature: _prepay.signature, // 必填，签名
                        jsApiList: ['chooseWXPay']
                    });
                    wx.ready(function(){
                        wx.chooseWXPay({
                            timestamp: _prepay.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符     
                            nonceStr: _prepay.nonceStr, // 支付签名随机串，不长于 32 位
                            package: _prepay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: _prepay.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: _prepay.paySign, // 支付签名
                            success: _ => {  // 支付成功后的回调函数
                                _that.$message.success('预约成功');
                                _that.$router.push({
                                    path: "/list"
                                });
                            },
                            fail: err => {
                                _that.$message.error(err.errMsg);
                            }
                        });
                    });
                });
        }
    }
};
</script>

<style scoped>
    #pay {
        text-align: center;
    }
    #pay div {
        padding-top: 120px;
    }
    .see-btn {
        width: 128px;
        height: 40px;
        padding: 0 30px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 50px;
        margin: 0 auto;
    }
</style>